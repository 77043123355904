import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide91/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide91/image/img.png"
import slider1 from "@components/pageGuide/guides/guide91/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide91/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide91/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Ecommerce",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Estrategias de bots conversacionales para ventas flash y promociones
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Los bots conversacionales ayudan a gestionar ventas flash y promociones en canales digitales. Automatizan respuestas para informar sobre descuentos y disponibilidad de productos.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Cómo configurar un bot para promociones.",
      },
      {
        key: 2,
        text: "Estrategias para mejorar la conversión"
      },
      {
        key: 3,
        text: "Automatización de respuestas en tiempo real.",
      },
      {
        key: 4,
        text: "Integración con diferentes canales.",
      },
      {
        key: 5,
        text: "Análisis de datos para optimizar campañas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Reducen el tiempo de espera al atender consultas de manera inmediata. Recuerda que, con esta guía, podrás conocer cómo configurar flujos de conversación para guiar a los clientes en el proceso de compra. Asegura una experiencia fluida al integrar opciones de pago y confirmación de pedidos.
          <br /><br />
          Por último, accede a más detalles, configuraciones y herramientas recomendadas descargándola. Mejora la conversión en campañas de ventas.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
